
body {
  /* IOS禁止微信内置浏览器调整字体大小 */
  -webkit-text-size-adjust: 100% !important;
  -ms-text-size-adjust: 100% !important;
      text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  margin: 0;
  background: #f7f7f7;
  font-size: 16px;
}
.flex-box {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
}
.flex-item {
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
}

@media screen and (min-width: 1288px){
.bg[data-v-5ecbf129]{width:100%;height:960px;background-image:url(../../static/img/bg1.b3a8e10.png);background-size:cover;background-position-x:100%
}
.bg .logo-div[data-v-5ecbf129]{padding:40px 66px;color:#ffffff
}
.bg .logo-div img[data-v-5ecbf129]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-div h2[data-v-5ecbf129]{font-size:20px;margin:0
}
.bg .logo-div .content-name[data-v-5ecbf129]{font-size:14px;margin:0
}
.bg .mianshu[data-v-5ecbf129]{color:#ffffff;width:1068px;margin:0 auto
}
.bg .mianshu h2[data-v-5ecbf129]{font-size:52px;margin:80px 0 0 0
}
.bg .mianshu .content[data-v-5ecbf129]{margin:10px 0 0 0;font-size:24px
}
.bg .ready-code[data-v-5ecbf129]{width:1068px;margin:0 auto
}
.bg .ready-code img[data-v-5ecbf129]{width:220px;margin-right:145px;margin-top:116px
}
.bg .ready-code .code-title[data-v-5ecbf129]{width:220px;padding-top:10px;color:#ffffff;text-align:center;font-size:20px
}
.bg .down[data-v-5ecbf129]{display:none
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-5ecbf129]{width:100%;height:800px;background-image:url(../../static/img/bg1.b3a8e10.png);background-size:cover;background-position-x:100%
}
.bg .logo-div[data-v-5ecbf129]{padding:40px 66px;color:#ffffff
}
.bg .logo-div img[data-v-5ecbf129]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-div h2[data-v-5ecbf129]{font-size:20px;margin:0
}
.bg .logo-div .content-name[data-v-5ecbf129]{font-size:14px;margin:0
}
.bg .mianshu[data-v-5ecbf129]{color:#ffffff;width:820px;margin:0 auto
}
.bg .mianshu h2[data-v-5ecbf129]{font-size:32px;margin:60px 0 0 0
}
.bg .mianshu .content[data-v-5ecbf129]{margin:10px 0 0 0;font-size:20px
}
.bg .ready-code[data-v-5ecbf129]{width:820px;margin:0 auto
}
.bg .ready-code img[data-v-5ecbf129]{width:180px;margin-right:110px;margin-top:60px
}
.bg .ready-code .code-title[data-v-5ecbf129]{width:180px;padding-top:10px;color:#ffffff;font-size:20px;text-align:center
}
.bg .down[data-v-5ecbf129]{display:none
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-5ecbf129]{width:100%;height:600px;background-image:url(../../static/img/bg1.b3a8e10.png);background-size:cover;background-position-x:100%
}
.bg .logo-div[data-v-5ecbf129]{padding:40px 66px;color:#ffffff
}
.bg .logo-div img[data-v-5ecbf129]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-div h2[data-v-5ecbf129]{font-size:20px;margin:0
}
.bg .logo-div .content-name[data-v-5ecbf129]{font-size:14px;margin:0
}
.bg .mianshu[data-v-5ecbf129]{color:#ffffff;width:600px;margin:0 auto
}
.bg .mianshu h2[data-v-5ecbf129]{font-size:28px;margin:40px 0 0 0
}
.bg .mianshu .content[data-v-5ecbf129]{margin:10px 0 0 0;font-size:20px
}
.bg .ready-code[data-v-5ecbf129]{width:600px;margin:0 auto
}
.bg .ready-code img[data-v-5ecbf129]{width:120px;margin-right:110px;margin-top:60px
}
.bg .ready-code .code-title[data-v-5ecbf129]{width:120px;padding-top:10px;color:#ffffff;font-size:16px;text-align:center
}
.down[data-v-5ecbf129]{display:none
}
}
@media screen and (max-width: 768px){
.bg[data-v-5ecbf129]{position:relative;width:100%;height:5.44rem;background-image:url(../../static/img/bg1.b3a8e10.png);background-size:cover;background-position-x:100%
}
.bg .logo-div[data-v-5ecbf129]{display:none
}
.bg .mianshu[data-v-5ecbf129]{color:#ffffff;margin:0 auto 0 0.4rem
}
.bg .mianshu h2[data-v-5ecbf129]{font-size:0.36rem;margin:0;padding:0.47rem 0 0 0
}
.bg .mianshu .content[data-v-5ecbf129]{padding:0.2rem 0 0 0;margin:0;font-size:0.26rem
}
.bg .ready-code[data-v-5ecbf129]{width:2.2rem;margin:0 auto 0 15%
}
.bg .ready-code img[data-v-5ecbf129]{width:2.2rem;height:2.2rem;margin-top:0.8rem
}
.bg .ready-code .code-title[data-v-5ecbf129]{width:2.2rem;color:#ffffff;font-size:0.24rem;text-align:center
}
.bg .ready-code .shao[data-v-5ecbf129]{display:none
}
.bg .down[data-v-5ecbf129]{cursor:pointer;position:absolute;bottom:-0.87rem;display:block;left:50%;transform:translateX(-50%);-ms-transform:translateX(-50%);-moz-transform:translateX(-50%);-webkit-transform:translateX(-50%);-o-transform:translateX(-50%);margin:0 auto;width:6.58rem;height:1.2rem;background-image:-webkit-gradient(linear, right top, left top, from(#fc1983), to(#fcb119)),-webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));background-image:linear-gradient(-90deg, #fc1983 0%, #fcb119 100%),linear-gradient(#fff, #fff);background-blend-mode:normal, normal;border-radius:0.6rem
}
.bg .down .down-content[data-v-5ecbf129]{width:5.63rem;height:0.79rem;margin:0.21rem auto 0 auto
}
.bg .down .down-content .logo[data-v-5ecbf129]{width:0.75rem;height:0.75rem;border-radius:0.1rem;margin-right:0.2rem
}
.bg .down .down-content .down-title[data-v-5ecbf129]{font-size:0.3rem;color:#ffffff
}
.bg .down .down-content .down-title2[data-v-5ecbf129]{font-size:0.24rem;color:#ffffff
}
.bg .down .down-content .downpng[data-v-5ecbf129]{width:0.52rem;height:0.48rem;margin-top:0.15rem
}
}

.zhuan[data-v-fa280db4]{-webkit-transition-property:-webkit-transform;-webkit-transition-duration:1s;-moz-transition-property:-moz-transform;-moz-transition-duration:1s;-webkit-animation:rotate-data-v-fa280db4 0.5s linear infinite;animation:rotate-data-v-fa280db4 0.5s linear infinite
}
@-webkit-keyframes rotate-data-v-fa280db4{
from{-webkit-transform:rotate(0deg)
}
to{-webkit-transform:rotate(360deg)
}
}
@keyframes rotate-data-v-fa280db4{
from{-webkit-transform:rotate(0deg);transform:rotate(0deg)
}
to{-webkit-transform:rotate(359deg);transform:rotate(359deg)
}
}
@media screen and (min-width: 1288px){
.bg[data-v-fa280db4]{width:100%;background-color:#ffffff;padding-bottom:111px
}
.bg .title[data-v-fa280db4]{width:100%;text-align:center
}
.bg .title .title1[data-v-fa280db4]{color:#853d03;font-size:40px;padding-top:100px;margin:0
}
.bg .title .title1 span[data-v-fa280db4]{color:#fc1983
}
.bg .title .title2[data-v-fa280db4]{color:#999999;font-size:26px;margin-top:15px;padding-bottom:60px
}
.bg .list[data-v-fa280db4]{width:1240px;background-color:#ffffff;-webkit-box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);border-radius:20px;margin:0 auto;padding:40px 0px 10px 30px
}
.bg .list .top[data-v-fa280db4]{font-size:24px;padding-bottom:40px
}
.bg .list .top img[data-v-fa280db4]{margin:auto 10px auto 40px;vertical-align:middle
}
.bg .list .top .huan[data-v-fa280db4]{color:#fcb119
}
.bg .list .list-content .content-img[data-v-fa280db4]{width:280px;height:402px;background-position:50% 50%;background-size:cover;border-radius:10px
}
.bg .list .list-content .bottom[data-v-fa280db4]{color:#999999;width:250px;padding:20px 15px 20px 15px
}
.bg .list .list-content .bottom img[data-v-fa280db4]{width:20px
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-fa280db4]{width:100%;background-color:#ffffff;padding-bottom:111px
}
.bg .title[data-v-fa280db4]{width:100%;text-align:center
}
.bg .title .title1[data-v-fa280db4]{color:#853d03;font-size:40px;padding-top:100px;margin:0
}
.bg .title .title1 span[data-v-fa280db4]{color:#fc1983
}
.bg .title .title2[data-v-fa280db4]{color:#999999;font-size:20px;margin-top:15px;padding-bottom:60px
}
.bg .list[data-v-fa280db4]{width:917px;background-color:#ffffff;-webkit-box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);border-radius:20px;margin:0 auto;padding:40px 0px 10px 30px
}
.bg .list .top[data-v-fa280db4]{font-size:24px;padding-bottom:40px
}
.bg .list .top img[data-v-fa280db4]{margin:auto 10px auto 40px;vertical-align:middle
}
.bg .list .top .huan[data-v-fa280db4]{color:#fcb119
}
.bg .list .list-content .content-img[data-v-fa280db4]{width:190px;height:280px;background-position:50% 50%;background-size:cover;border-radius:10px
}
.bg .list .list-content .bottom[data-v-fa280db4]{color:#999999;width:170px;padding:20px 15px 20px 15px
}
.bg .list .list-content .bottom img[data-v-fa280db4]{width:20px
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-fa280db4]{width:100%;background-color:#ffffff;padding-bottom:111px
}
.bg .title[data-v-fa280db4]{width:100%;text-align:center
}
.bg .title .title1[data-v-fa280db4]{color:#853d03;font-size:32px;padding-top:100px;margin:0
}
.bg .title .title1 span[data-v-fa280db4]{color:#fc1983
}
.bg .title .title2[data-v-fa280db4]{color:#999999;font-size:20px;margin-top:15px;padding-bottom:60px
}
.bg .list[data-v-fa280db4]{width:725px;background-color:#ffffff;-webkit-box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);box-shadow:0px 13px 33px 2px rgba(136,136,136,0.15);border-radius:20px;margin:0 auto;padding:40px 0px 10px 25px
}
.bg .list .top[data-v-fa280db4]{font-size:24px;padding-bottom:40px
}
.bg .list .top img[data-v-fa280db4]{margin:auto 10px auto 40px;vertical-align:middle
}
.bg .list .top .huan[data-v-fa280db4]{color:#fcb119
}
.bg .list .list-content .content-img[data-v-fa280db4]{width:150px;height:210px;background-position:50% 50%;background-size:cover;border-radius:10px
}
.bg .list .list-content .bottom[data-v-fa280db4]{color:#999999;width:130px;padding:15px 10px 15px 10px
}
.bg .list .list-content .bottom img[data-v-fa280db4]{width:20px
}
}
@media screen and (max-width: 768px){
.bg[data-v-fa280db4]{width:100%;background-color:#ffffff;padding:1rem 0 0.5rem 0
}
.bg .title[data-v-fa280db4]{width:100%;text-align:center
}
.bg .title .title1[data-v-fa280db4]{color:#853d03;font-size:0.36rem;padding:1rem 0.4rem 0.28rem 0.4rem;margin:0
}
.bg .title .title1 span[data-v-fa280db4]{color:#fc1983
}
.bg .title .title2[data-v-fa280db4]{color:#999999;font-size:0.26rem;padding-bottom:0.4rem
}
.bg .list[data-v-fa280db4]{background-color:#ffffff;border-radius:0.2rem;margin:0 auto
}
.bg .list .top[data-v-fa280db4]{font-size:0.3rem;padding:0.3rem;font-weight:bold
}
.bg .list .top img[data-v-fa280db4]{margin:auto 0.1rem auto 0.3rem;vertical-align:middle;width:0.4rem;height:0.4rem
}
.bg .list .top .huan[data-v-fa280db4]{color:#fcb119
}
.bg .list .scroll-div[data-v-fa280db4]{width:100%;overflow-x:scroll
}
.bg .list .scroll-div .no-flex[data-v-fa280db4]{width:12.88rem;padding-right:0.25rem
}
.bg .list .scroll-div .no-flex .list-content[data-v-fa280db4]{margin-left:0.3rem
}
.bg .list .scroll-div .no-flex .list-content .content-img[data-v-fa280db4]{width:2.8rem;height:4.02rem;background-position:50% 50%;background-size:cover;border-radius:0.1rem
}
.bg .list .scroll-div .no-flex .list-content .bottom[data-v-fa280db4]{color:#999999;width:2.5rem;font-size:0.26rem;padding:0.15rem 0.1rem 0.15rem 0.1rem
}
.bg .list .scroll-div .no-flex .list-content .bottom img[data-v-fa280db4]{width:0.2rem
}
}

@media screen and (min-width: 1288px){
.rem-bg[data-v-2af39607]{display:none
}
.bg[data-v-2af39607]{width:100%;height:970px;background-position:50% 50%;background-size:cover;background-image:url(../../static/img/bg3.fd4fca5.png)
}
.bg .title[data-v-2af39607]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-2af39607]{margin:0;padding-top:40px;font-size:40px
}
.bg .title .h3[data-v-2af39607]{margin-top:15px;font-size:24px
}
.bg .center[data-v-2af39607]{width:1200px;height:613px;margin:100px auto 0 auto
}
.bg .center .content-one[data-v-2af39607]{overflow:hidden;width:295px;margin:0 40px;height:613px;background-color:#ededed;border-radius:32px;border:solid 1px #cfcfcf
}
.bg .center .content-one .content-overflow[data-v-2af39607]{overflow-y:scroll;border-radius:22px;background-color:#ffffff;height:613px;margin-right:-17px
}
.bg .center .content-one .content-top[data-v-2af39607]{border-top-left-radius:22px;border-top-right-radius:22px;padding:15px;background-color:#ffffff
}
.bg .center .content-one .content-top .touxiang[data-v-2af39607]{min-width:40px;width:40px;height:40px;margin-right:10px;background-size:cover;background-position:50%;border-radius:5px
}
.bg .center .content-one .content-top .tou-center .name[data-v-2af39607]{font-size:16px;color:#fc288b;font-weight:bold
}
.bg .center .content-one .content-top .tou-center .address[data-v-2af39607]{font-size:14px;height:20px;overflow:hidden;color:#adadad
}
.bg .center .content-one .content-top .tou-center .address img[data-v-2af39607]{width:10px;vertical-align:middle
}
.bg .center .content-one .content-top .time[data-v-2af39607]{color:#adadad
}
.bg .center .content-one .content-title[data-v-2af39607]{background-color:#ffffff;padding:0 15px 15px 15px;text-align:justify;line-height:30px
}
.bg .center .content-one .content-img-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.bg .center .content-one .content-img-div .content-img[data-v-2af39607]{width:270px;margin:0 auto
}
.bg .center .content-one .content-img-div .content-img img[data-v-2af39607]{width:100%
}
.bg .center .content-one .video-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.bg .center .content-one .video-div .video-content[data-v-2af39607]{width:270px;margin:0 auto
}
.bg .center .content-one .video-div .video-content .video-play[data-v-2af39607]{width:100%;cursor:pointer
}
.bg .center .content-one .pinlun[data-v-2af39607]{font-size:14px;padding:15px;background-color:#ffffff
}
.bg .center .content-one .pinlun .pin-over[data-v-2af39607]{height:46px;overflow:hidden
}
.bg .center .content-one .pinlun .neirong[data-v-2af39607]{line-height:23px
}
.bg .center .content-one .pinlun .neirong .name[data-v-2af39607]{color:#06cae1
}
.bg .center .content-one .pinlun .more[data-v-2af39607]{margin-top:5px;color:#adadad;cursor:pointer
}
.bg .center .content-one .zan-div[data-v-2af39607]{background:#ffffff;vertical-align:middle;padding-bottom:15px;border-bottom-left-radius:22px;border-bottom-right-radius:22px
}
.bg .center .content-one .zan-div .zan-item[data-v-2af39607]{text-align:center
}
.bg .center .content-one .zan-div .zan-item img[data-v-2af39607]{vertical-align:middle;width:20px;height:20px
}
.bg .center .content-one .zan-div .zan-item .zan-num[data-v-2af39607]{color:#adadad
}
.bg .center .content-padding[data-v-2af39607]{padding:20px;position:relative;top:-20px;overflow:hidden
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.rem-bg[data-v-2af39607]{display:none
}
.bg[data-v-2af39607]{width:100%;height:880px;background-position:50% 50%;background-size:cover;background-image:url(../../static/img/bg3.fd4fca5.png)
}
.bg .title[data-v-2af39607]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-2af39607]{margin:0;padding-top:40px;font-size:40px
}
.bg .title .h3[data-v-2af39607]{margin-top:15px;font-size:24px
}
.bg .flex-scrllo .center[data-v-2af39607]{width:930px;height:500px;margin:100px auto 0 auto
}
.bg .flex-scrllo .center .content-one[data-v-2af39607]{overflow:hidden;width:295px;margin:0 10px;height:500px;background-color:#ededed;border-radius:32px;border:solid 1px #cfcfcf
}
.bg .flex-scrllo .center .content-one .content-overflow[data-v-2af39607]{overflow-y:scroll;border-radius:22px;background-color:#ffffff;height:500px;margin-right:-17px
}
.bg .flex-scrllo .center .content-one .content-top[data-v-2af39607]{border-top-left-radius:22px;border-top-right-radius:22px;padding:15px;background-color:#ffffff
}
.bg .flex-scrllo .center .content-one .content-top .touxiang[data-v-2af39607]{min-width:40px;width:40px;height:40px;margin-right:10px;background-size:cover;background-position:50%;border-radius:5px
}
.bg .flex-scrllo .center .content-one .content-top .tou-center .name[data-v-2af39607]{font-size:16px;color:#fc288b;font-weight:bold
}
.bg .flex-scrllo .center .content-one .content-top .tou-center .address[data-v-2af39607]{font-size:14px;height:20px;color:#adadad;overflow:hidden
}
.bg .flex-scrllo .center .content-one .content-top .tou-center .address img[data-v-2af39607]{width:10px;vertical-align:middle
}
.bg .flex-scrllo .center .content-one .content-top .time[data-v-2af39607]{color:#adadad
}
.bg .flex-scrllo .center .content-one .content-title[data-v-2af39607]{background-color:#ffffff;padding:0 15px 15px 15px;text-align:justify;line-height:30px
}
.bg .flex-scrllo .center .content-one .content-img-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.bg .flex-scrllo .center .content-one .content-img-div .content-img[data-v-2af39607]{width:270px;margin:0 auto
}
.bg .flex-scrllo .center .content-one .content-img-div .content-img img[data-v-2af39607]{width:100%
}
.bg .flex-scrllo .center .content-one .video-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.bg .flex-scrllo .center .content-one .video-div .video-content[data-v-2af39607]{width:270px;margin:0 auto
}
.bg .flex-scrllo .center .content-one .video-div .video-content .video-play[data-v-2af39607]{width:100%;cursor:pointer
}
.bg .flex-scrllo .center .content-one .pinlun[data-v-2af39607]{font-size:14px;padding:15px;background-color:#ffffff
}
.bg .flex-scrllo .center .content-one .pinlun .pin-over[data-v-2af39607]{height:46px;overflow:hidden
}
.bg .flex-scrllo .center .content-one .pinlun .neirong[data-v-2af39607]{line-height:23px
}
.bg .flex-scrllo .center .content-one .pinlun .neirong .name[data-v-2af39607]{color:#06cae1
}
.bg .flex-scrllo .center .content-one .pinlun .more[data-v-2af39607]{margin-top:5px;cursor:pointer;color:#adadad
}
.bg .flex-scrllo .center .content-one .zan-div[data-v-2af39607]{background:#ffffff;vertical-align:middle;padding-bottom:15px;border-bottom-left-radius:22px;border-bottom-right-radius:22px
}
.bg .flex-scrllo .center .content-one .zan-div .zan-item[data-v-2af39607]{text-align:center
}
.bg .flex-scrllo .center .content-one .zan-div .zan-item img[data-v-2af39607]{vertical-align:middle;width:20px;height:20px
}
.bg .flex-scrllo .center .content-one .zan-div .zan-item .zan-num[data-v-2af39607]{color:#adadad
}
.bg .flex-scrllo .center .content-padding[data-v-2af39607]{padding:20px;position:relative;top:-20px
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-2af39607]{display:none
}
.rem-bg[data-v-2af39607]{width:100%;background-color:#ffffff;position:relative;height:12.5rem
}
.rem-bg .bg2[data-v-2af39607]{width:100%;height:5.5rem;background-position:50% 50%;background-size:cover;background-image:url(../../static/img/bg3.fd4fca5.png);position:absolute;top:2.4rem
}
.rem-bg .rem-bg-content[data-v-2af39607]{position:absolute;margin-left:50%;transform:translateX(-50%);-ms-transform:translateX(-50%);-moz-transform:translateX(-50%);-webkit-transform:translateX(-50%);-o-transform:translateX(-50%)
}
.rem-bg .rem-bg-content .title[data-v-2af39607]{color:#853d03;text-align:center
}
.rem-bg .rem-bg-content .title h1[data-v-2af39607]{margin:0;padding-top:0.5rem;font-size:0.36rem
}
.rem-bg .rem-bg-content .title .h3[data-v-2af39607]{margin:0.24rem 0 0.56rem 0;font-size:0.26rem;color:#999999
}
.rem-bg .rem-bg-content .phone[data-v-2af39607]{width:4.98rem;height:9.6rem;padding-top:0.35rem;background-color:#ededed;border-radius:0.5rem;margin:0 auto
}
.rem-bg .rem-bg-content .phone2[data-v-2af39607]{width:4.47rem;height:9.28rem;background-color:#ffffff;border-radius:0.36rem;margin:0 auto;overflow-y:scroll
}
.rem-bg .rem-bg-content .phone2 .content-top[data-v-2af39607]{padding:0.15rem;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .content-top .touxiang[data-v-2af39607]{min-width:0.8rem;width:0.8rem;height:0.8rem;margin-right:0.15rem;background-size:cover;background-position:50%;border-radius:0.1rem
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .name[data-v-2af39607]{font-size:0.3rem;color:#fc288b;font-weight:bold;line-height:0.4rem;overflow:hidden;height:0.4rem
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .address[data-v-2af39607]{font-size:0.26rem;line-height:0.4rem;height:0.4rem;overflow:hidden;color:#adadad
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .address img[data-v-2af39607]{width:0.2rem;vertical-align:middle;position:relative;top:-0.05rem
}
.rem-bg .rem-bg-content .phone2 .content-top .time[data-v-2af39607]{color:#adadad;font-size:0.24rem
}
.rem-bg .rem-bg-content .phone2 .content-title[data-v-2af39607]{background-color:#ffffff;padding:0.15rem;text-align:justify;line-height:0.5rem;font-size:0.36rem
}
.rem-bg .rem-bg-content .phone2 .content-img-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .content-img-div .content-img[data-v-2af39607]{width:2.7rem;margin:auto 0.15rem
}
.rem-bg .rem-bg-content .phone2 .content-img-div .content-img img[data-v-2af39607]{width:100%
}
.rem-bg .rem-bg-content .phone2 .video-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .video-div .video-content[data-v-2af39607]{width:2.7rem;margin:auto 0.15rem
}
.rem-bg .rem-bg-content .phone2 .video-div .video-content .video-play[data-v-2af39607]{width:100%;cursor:pointer
}
.rem-bg .rem-bg-content .phone2 .pinlun[data-v-2af39607]{font-size:0.26rem;padding:0.15rem;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .pinlun .pin-over[data-v-2af39607]{height:0.8rem;overflow:hidden
}
.rem-bg .rem-bg-content .phone2 .pinlun .neirong[data-v-2af39607]{line-height:0.4rem
}
.rem-bg .rem-bg-content .phone2 .pinlun .neirong .name[data-v-2af39607]{color:#06cae1
}
.rem-bg .rem-bg-content .phone2 .pinlun .more[data-v-2af39607]{margin-top:0.05rem;cursor:pointer;color:#adadad
}
.rem-bg .rem-bg-content .phone2 .zan-div[data-v-2af39607]{background:#ffffff;vertical-align:middle;padding-bottom:0.15rem;font-size:0.26rem
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item[data-v-2af39607]{text-align:center
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item img[data-v-2af39607]{vertical-align:middle;width:0.3rem;height:0.3rem
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item .zan-num[data-v-2af39607]{color:#adadad
}
.rem-bg .rem-bg-content .phone2 .geli[data-v-2af39607]{width:100%;height:0.2rem;background-color:#ededed
}
}
@media screen and (max-width: 768px){
.bg[data-v-2af39607]{display:none
}
.rem-bg[data-v-2af39607]{width:100%;background-color:#ffffff;position:relative;height:13.5rem
}
.rem-bg .bg2[data-v-2af39607]{width:100%;height:5.5rem;background-position:50% 50%;background-size:cover;background-image:url(../../static/img/bg3.fd4fca5.png);position:absolute;top:2.4rem
}
.rem-bg .rem-bg-content[data-v-2af39607]{position:absolute;margin-left:50%;transform:translateX(-50%);-ms-transform:translateX(-50%);-moz-transform:translateX(-50%);-webkit-transform:translateX(-50%);-o-transform:translateX(-50%)
}
.rem-bg .rem-bg-content .title[data-v-2af39607]{color:#853d03;text-align:center
}
.rem-bg .rem-bg-content .title h1[data-v-2af39607]{margin:0;padding-top:0.5rem;font-size:0.36rem
}
.rem-bg .rem-bg-content .title .h3[data-v-2af39607]{margin:0.24rem 0 0.56rem 0;font-size:0.26rem;color:#999999
}
.rem-bg .rem-bg-content .phone[data-v-2af39607]{width:4.98rem;height:9.6rem;padding-top:0.35rem;background-color:#ededed;border-radius:0.5rem;margin:0 auto
}
.rem-bg .rem-bg-content .phone2[data-v-2af39607]{width:4.47rem;height:9.28rem;background-color:#ffffff;border-radius:0.36rem;margin:0 auto;overflow-y:scroll
}
.rem-bg .rem-bg-content .phone2 .content-top[data-v-2af39607]{padding:0.15rem;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .content-top .touxiang[data-v-2af39607]{min-width:0.8rem;width:0.8rem;height:0.8rem;margin-right:0.15rem;background-size:cover;background-position:50%;border-radius:0.1rem
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .name[data-v-2af39607]{font-size:0.3rem;color:#fc288b;font-weight:bold;line-height:0.4rem;overflow:hidden;height:0.4rem
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .address[data-v-2af39607]{font-size:0.26rem;line-height:0.4rem;height:0.4rem;overflow:hidden;color:#adadad
}
.rem-bg .rem-bg-content .phone2 .content-top .tou-center .address img[data-v-2af39607]{width:0.2rem;vertical-align:middle;position:relative;top:-0.05rem
}
.rem-bg .rem-bg-content .phone2 .content-top .time[data-v-2af39607]{color:#adadad;font-size:0.24rem
}
.rem-bg .rem-bg-content .phone2 .content-title[data-v-2af39607]{background-color:#ffffff;padding:0.15rem;text-align:justify;line-height:0.5rem;font-size:0.36rem
}
.rem-bg .rem-bg-content .phone2 .content-img-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .content-img-div .content-img[data-v-2af39607]{width:2.7rem;margin:auto 0.15rem
}
.rem-bg .rem-bg-content .phone2 .content-img-div .content-img img[data-v-2af39607]{width:100%
}
.rem-bg .rem-bg-content .phone2 .video-div[data-v-2af39607]{width:100%;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .video-div .video-content[data-v-2af39607]{width:2.7rem;margin:auto 0.15rem
}
.rem-bg .rem-bg-content .phone2 .video-div .video-content .video-play[data-v-2af39607]{width:100%;cursor:pointer
}
.rem-bg .rem-bg-content .phone2 .pinlun[data-v-2af39607]{font-size:0.26rem;padding:0.15rem;background-color:#ffffff
}
.rem-bg .rem-bg-content .phone2 .pinlun .pin-over[data-v-2af39607]{height:0.8rem;overflow:hidden
}
.rem-bg .rem-bg-content .phone2 .pinlun .neirong[data-v-2af39607]{line-height:0.4rem
}
.rem-bg .rem-bg-content .phone2 .pinlun .neirong .name[data-v-2af39607]{color:#06cae1
}
.rem-bg .rem-bg-content .phone2 .pinlun .more[data-v-2af39607]{margin-top:0.05rem;cursor:pointer;color:#adadad
}
.rem-bg .rem-bg-content .phone2 .zan-div[data-v-2af39607]{background:#ffffff;vertical-align:middle;padding-bottom:0.15rem;font-size:0.26rem
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item[data-v-2af39607]{text-align:center
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item img[data-v-2af39607]{vertical-align:middle;width:0.3rem;height:0.3rem
}
.rem-bg .rem-bg-content .phone2 .zan-div .zan-item .zan-num[data-v-2af39607]{color:#adadad
}
.rem-bg .rem-bg-content .phone2 .geli[data-v-2af39607]{width:100%;height:0.2rem;background-color:#ededed
}
}

@media screen and (min-width: 1288px){
.bg[data-v-4c793c70]{width:1288px;margin:0 auto;background-color:#ffffff
}
.bg .title[data-v-4c793c70]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-4c793c70]{margin:0;padding-top:100px;font-size:40px;color:#853d03
}
.bg .title .fu-title[data-v-4c793c70]{margin-top:15px;font-size:24px;color:#999999
}
.bg .list-div[data-v-4c793c70]{padding:100px 0
}
.bg .list-div .list-item[data-v-4c793c70]{text-align:center
}
.bg .list-div .list-item h2[data-v-4c793c70]{font-size:30px;color:#333333;margin-top:60px;margin-bottom:20px
}
.bg .list-div .list-item .fu-fu-title[data-v-4c793c70]{margin:0px;font-size:22px;color:#999999
}
.bg .m-list-div[data-v-4c793c70]{display:none
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-4c793c70]{width:960px;margin:0 auto;background-color:#ffffff
}
.bg .title[data-v-4c793c70]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-4c793c70]{margin:0;padding-top:100px;font-size:40px;color:#853d03
}
.bg .title .fu-title[data-v-4c793c70]{margin-top:15px;font-size:24px;color:#999999
}
.bg .list-div[data-v-4c793c70]{padding:100px 0
}
.bg .list-div .list-item[data-v-4c793c70]{text-align:center
}
.bg .list-div .list-item h2[data-v-4c793c70]{font-size:28px;color:#333333;margin-top:60px;margin-bottom:20px
}
.bg .list-div .list-item .fu-fu-title[data-v-4c793c70]{margin:0px;font-size:18px;color:#999999
}
.bg .m-list-div[data-v-4c793c70]{display:none
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-4c793c70]{width:769px;margin:0 auto;background-color:#ffffff
}
.bg .title[data-v-4c793c70]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-4c793c70]{margin:0;padding-top:100px;font-size:40px;color:#853d03
}
.bg .title .fu-title[data-v-4c793c70]{margin-top:15px;font-size:24px;color:#999999
}
.bg .list-div[data-v-4c793c70]{padding:100px 0
}
.bg .list-div .list-item[data-v-4c793c70]{text-align:center
}
.bg .list-div .list-item h2[data-v-4c793c70]{font-size:28px;color:#333333;margin-top:60px;margin-bottom:20px
}
.bg .list-div .list-item .fu-fu-title[data-v-4c793c70]{margin:0px;font-size:18px;color:#999999
}
.bg .m-list-div[data-v-4c793c70]{display:none
}
}
@media screen and (max-width: 768px){
.bg[data-v-4c793c70]{width:100%;margin:0 auto;background-color:#ffffff
}
.bg .title[data-v-4c793c70]{color:#ffffff;text-align:center
}
.bg .title h1[data-v-4c793c70]{margin:0;font-size:0.36rem;color:#853d03
}
.bg .title .fu-title[data-v-4c793c70]{margin-top:0.25rem;font-size:0.26rem;color:#999999
}
.bg .list-div[data-v-4c793c70]{display:none
}
.bg .m-list-div[data-v-4c793c70]{width:6.24rem;margin:0 auto;padding-bottom:0.5rem
}
.bg .m-list-div .m-list-item[data-v-4c793c70]{margin-top:0.5rem
}
.bg .m-list-div .m-list-item img[data-v-4c793c70]{width:1.7rem;min-width:1.7rem;height:1.7rem;margin-right:0.5rem
}
.bg .m-list-div .m-list-item h2[data-v-4c793c70]{color:#333333;font-size:0.36rem
}
.bg .m-list-div .m-list-item .fu-fu-title[data-v-4c793c70]{color:#999999;font-size:0.3rem;margin:0
}
}

li[data-v-53aa4e73]:hover{color:#853d03
}
a[data-v-53aa4e73]{color:#000
}
a[data-v-53aa4e73]:hover{color:#853d03
}
@media screen and (min-width: 1288px){
.content[data-v-53aa4e73]{background-color:#f5f5f5
}
.content .bg[data-v-53aa4e73]{width:1200px;padding-bottom:65px;margin:0 auto
}
.content .bg .bg-content[data-v-53aa4e73]{margin:0 auto;width:1090px
}
.content .bg .left[data-v-53aa4e73]{vertical-align:top;width:489px;display:inline-block
}
.content .bg .left h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .left ul[data-v-53aa4e73]{margin:0;list-style:none;padding:30px;background-color:#e4e4e4;font-size:16px
}
.content .bg .left ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;cursor:pointer;text-decoration:underline;margin-top:10px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.content .bg .right[data-v-53aa4e73]{vertical-align:top;width:489px;display:inline-block;margin-left:100px
}
.content .bg .right h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .right ul[data-v-53aa4e73]{margin:0;padding:30px;list-style:none;background-color:#e4e4e4;font-size:16px
}
.content .bg .right ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;cursor:pointer;text-decoration:underline;margin-top:10px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.content[data-v-53aa4e73]{background-color:#f5f5f5
}
.content .bg[data-v-53aa4e73]{width:960px;padding-bottom:65px;margin:0 auto
}
.content .bg .bg-content[data-v-53aa4e73]{margin:0 auto;width:800px
}
.content .bg .left[data-v-53aa4e73]{vertical-align:top;width:370px;display:inline-block
}
.content .bg .left h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .left ul[data-v-53aa4e73]{margin:0;list-style:none;padding:30px;background-color:#e4e4e4;font-size:16px
}
.content .bg .left ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;cursor:pointer;text-decoration:underline;margin-top:10px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.content .bg .right[data-v-53aa4e73]{vertical-align:top;width:370px;display:inline-block;margin-left:50px
}
.content .bg .right h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .right ul[data-v-53aa4e73]{margin:0;padding:30px;list-style:none;background-color:#e4e4e4;font-size:16px
}
.content .bg .right ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;cursor:pointer;text-decoration:underline;margin-top:10px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.content[data-v-53aa4e73]{background-color:#f5f5f5
}
.content .bg[data-v-53aa4e73]{width:769px;padding-bottom:65px;margin:0 auto
}
.content .bg .bg-content[data-v-53aa4e73]{margin:0 auto;width:700px
}
.content .bg .left[data-v-53aa4e73]{vertical-align:top;width:320px;display:inline-block
}
.content .bg .left h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .left ul[data-v-53aa4e73]{margin:0;list-style:none;padding:20px;background-color:#e4e4e4;font-size:16px
}
.content .bg .left ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;margin-top:10px;cursor:pointer;text-decoration:underline;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.content .bg .right[data-v-53aa4e73]{vertical-align:top;width:320px;display:inline-block;margin-left:50px
}
.content .bg .right h4[data-v-53aa4e73]{font-size:24px
}
.content .bg .right ul[data-v-53aa4e73]{margin:0;padding:20px;list-style:none;background-color:#e4e4e4;font-size:16px
}
.content .bg .right ul li[data-v-53aa4e73]{letter-spacing:0px;line-height:25px;cursor:pointer;text-decoration:underline;margin-top:10px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
}
@media screen and (max-width: 768px){
.content[data-v-53aa4e73]{background-color:#ffffff;padding:0.5rem 0 0.7rem 0
}
.content .bg[data-v-53aa4e73]{margin:0 auto
}
.content .bg .left[data-v-53aa4e73]{width:6.88rem;margin:0 auto
}
.content .bg .left h4[data-v-53aa4e73]{font-size:0.36rem;margin:0;padding-bottom:0.3rem
}
.content .bg .left ul[data-v-53aa4e73]{margin:0;list-style:none;padding:0.2rem 0.3rem 0.3rem 0.3rem;background-color:#e4e4e4;font-size:0.3rem
}
.content .bg .left ul li[data-v-53aa4e73]{color:#7a7a7a;letter-spacing:0px;line-height:0.4rem;margin-top:0.1rem;cursor:pointer;text-decoration:underline;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
.content .bg .right[data-v-53aa4e73]{width:6.88rem;margin:0.4rem auto 0 auto
}
.content .bg .right h4[data-v-53aa4e73]{font-size:0.36rem;margin:0;padding-bottom:0.3rem
}
.content .bg .right ul[data-v-53aa4e73]{margin:0;padding:0.2rem 0.3rem 0.3rem 0.3rem;list-style:none;background-color:#e4e4e4;font-size:0.3rem
}
.content .bg .right ul li[data-v-53aa4e73]{color:#7a7a7a;letter-spacing:0px;line-height:0.4rem;cursor:pointer;text-decoration:underline;margin-top:0.1rem;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}
}

a[data-v-0fd54554]{color:#ffffff
}
a[data-v-0fd54554]:hover{color:#06cae1
}
@media screen and (min-width: 1288px){
.bg[data-v-0fd54554]{padding:100px 0;background-color:#282828;font-size:14px;text-align:center
}
.bg .name[data-v-0fd54554]{color:#ffffff;padding-bottom:20px
}
.bg .phone[data-v-0fd54554]{color:#cccccc
}
.bg .phone .address[data-v-0fd54554]{margin-left:40px
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-0fd54554]{padding:100px 0;background-color:#282828;font-size:14px;text-align:center
}
.bg .name[data-v-0fd54554]{color:#ffffff;padding-bottom:20px
}
.bg .phone[data-v-0fd54554]{color:#cccccc
}
.bg .phone .address[data-v-0fd54554]{margin-left:40px
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-0fd54554]{padding:100px 0;background-color:#282828;font-size:14px;text-align:center
}
.bg .name[data-v-0fd54554]{color:#ffffff;padding-bottom:20px
}
.bg .phone[data-v-0fd54554]{color:#cccccc
}
.bg .phone .address[data-v-0fd54554]{margin-left:40px
}
}
@media screen and (max-width: 768px){
.bg[data-v-0fd54554]{padding:0.4rem 0.3rem;background-color:#282828;font-size:14px;font-size:0.24rem
}
.bg .name[data-v-0fd54554]{color:#ffffff;padding-bottom:0.2rem;font-size:0.3rem
}
.bg .phone[data-v-0fd54554]{color:#cccccc
}
.bg .phone .address[data-v-0fd54554]{display:block;margin-top:0.1rem
}
}

.fade-enter-active[data-v-73da586a],.fade-leave-active[data-v-73da586a]{-webkit-transition:opacity 0.5s;transition:opacity 0.5s
}
.fade-enter[data-v-73da586a],.fade-leave-to[data-v-73da586a]{opacity:0
}
a[data-v-73da586a]{color:#06cae1;text-decoration:none
}
.box[data-v-73da586a]{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);z-index:1001;color:#ffffff;text-align:center;background-color:#000;opacity:0.8
}
@media screen and (min-width: 1288px){
.box[data-v-73da586a]{padding:30px;border-radius:10px;font-size:20px
}
.box div[data-v-73da586a]{line-height:50px
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.box[data-v-73da586a]{padding:30px;border-radius:10px;font-size:20px
}
.box div[data-v-73da586a]{line-height:50px
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.box[data-v-73da586a]{padding:30px;border-radius:10px;font-size:20px
}
.box div[data-v-73da586a]{line-height:50px
}
}
@media screen and (max-width: 768px){
.box[data-v-73da586a]{padding:0.3rem;border-radius:0.1rem;font-size:0.26rem
}
.box div[data-v-73da586a]{line-height:0.6rem
}
}

@media screen and (min-width: 1288px){
.bg[data-v-49f712e6]{width:100%
}
.bg .logo-bg[data-v-49f712e6]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-49f712e6]{width:1200px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-49f712e6]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-49f712e6]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-49f712e6]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-49f712e6]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-49f712e6]{color:#ffffff;width:750px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-49f712e6]{font-size:30px;margin:0
}
.bg .center-bg .mianshu .content[data-v-49f712e6]{margin:10px 0 0 0;font-size:20px
}
.bg .center-bg .ready-code[data-v-49f712e6]{width:750px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-49f712e6]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-49f712e6]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-49f712e6]{width:100%
}
.bg .text-bg .content-center[data-v-49f712e6]{width:1200px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-49f712e6]{margin:0;font-size:24px;text-align:center;padding-top:33px
}
.bg .text-bg .content-center .shijian[data-v-49f712e6]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-49f712e6]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-49f712e6]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-49f712e6]{max-width:50%
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-49f712e6]{width:100%
}
.bg .logo-bg[data-v-49f712e6]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-49f712e6]{width:800px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-49f712e6]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-49f712e6]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-49f712e6]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-49f712e6]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-49f712e6]{color:#ffffff;width:750px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-49f712e6]{font-size:30px;margin:0
}
.bg .center-bg .mianshu .content[data-v-49f712e6]{margin:10px 0 0 0;font-size:14px
}
.bg .center-bg .ready-code[data-v-49f712e6]{width:750px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-49f712e6]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-49f712e6]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-49f712e6]{width:100%
}
.bg .text-bg .content-center[data-v-49f712e6]{width:800px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-49f712e6]{margin:0;font-size:24px;text-align:center;padding-top:33px
}
.bg .text-bg .content-center .shijian[data-v-49f712e6]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-49f712e6]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-49f712e6]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-49f712e6]{max-width:50%
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-49f712e6]{width:100%
}
.bg .logo-bg[data-v-49f712e6]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-49f712e6]{width:600px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-49f712e6]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-49f712e6]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-49f712e6]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-49f712e6]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-49f712e6]{color:#ffffff;width:600px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-49f712e6]{font-size:26px;margin:0
}
.bg .center-bg .mianshu .content[data-v-49f712e6]{margin:10px 0 0 0;font-size:14px
}
.bg .center-bg .ready-code[data-v-49f712e6]{width:600px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-49f712e6]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-49f712e6]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-49f712e6]{width:100%
}
.bg .text-bg .content-center[data-v-49f712e6]{width:600px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-49f712e6]{margin:0;font-size:24px;text-align:center;padding:33px 33px 0 33px
}
.bg .text-bg .content-center .shijian[data-v-49f712e6]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-49f712e6]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-49f712e6]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-49f712e6]{max-width:50%
}
}
@media screen and (max-width: 768px){
.bg[data-v-49f712e6]{width:100%
}
.bg .logo-bg[data-v-49f712e6]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-49f712e6]{width:6.9rem;margin:0 auto;padding:0.2rem 0rem
}
.bg .logo-bg .logo-div img[data-v-49f712e6]{width:0.9rem;height:0.9rem;min-width:0.9rem;margin-right:0.1rem;border-radius:0.1rem
}
.bg .logo-bg .logo-div h2[data-v-49f712e6]{font-size:0.36rem;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-49f712e6]{font-size:0.28rem;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-49f712e6]{width:100%;height:3.8rem;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;background-size:cover;opacity:0.9
}
.bg .center-bg .mianshu[data-v-49f712e6]{color:#ffffff;width:6.9rem;margin:0 auto;padding-top:0.3rem
}
.bg .center-bg .mianshu h2[data-v-49f712e6]{font-size:0.36rem;margin:0
}
.bg .center-bg .mianshu .content[data-v-49f712e6]{margin:0.1rem 0 0 0;font-size:0.26rem
}
.bg .center-bg .ready-code[data-v-49f712e6]{width:6.9rem;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-49f712e6]{width:1.23rem;margin-right:0.5rem;margin-top:0.33rem
}
.bg .center-bg .ready-code .code-title[data-v-49f712e6]{width:1.23rem;padding-top:0.1rem;color:#ffffff;text-align:center;font-size:0.2rem
}
.bg .text-bg[data-v-49f712e6]{width:100%
}
.bg .text-bg .content-center[data-v-49f712e6]{width:6.9rem;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-49f712e6]{margin:0;font-size:0.36rem;text-align:center;padding:0.2rem 0.2rem 0 0.2rem
}
.bg .text-bg .content-center .shijian[data-v-49f712e6]{margin:0.2rem 0 0 0;font-size:0.3rem;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-49f712e6]{padding:0 0.3rem;font-size:0.3rem;line-height:0.5rem
}
.bg .text-bg .content-center .content-div .wenzi[data-v-49f712e6]{padding:0.2rem 0;letter-spacing:0.03rem;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-49f712e6]{max-width:50%
}
}

@media screen and (min-width: 1288px){
.bg[data-v-6964f079]{width:100%
}
.bg .logo-bg[data-v-6964f079]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-6964f079]{width:1200px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-6964f079]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-6964f079]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-6964f079]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-6964f079]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-6964f079]{color:#ffffff;width:750px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-6964f079]{font-size:30px;margin:0
}
.bg .center-bg .mianshu .content[data-v-6964f079]{margin:10px 0 0 0;font-size:20px
}
.bg .center-bg .ready-code[data-v-6964f079]{width:750px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-6964f079]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-6964f079]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-6964f079]{width:100%
}
.bg .text-bg .content-center[data-v-6964f079]{width:1200px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-6964f079]{margin:0;font-size:24px;text-align:center;padding-top:33px
}
.bg .text-bg .content-center .shijian[data-v-6964f079]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-6964f079]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-6964f079]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-6964f079]{max-width:50%
}
.bg .text-bg .content-center .pinlun[data-v-6964f079]{padding:10px 50px;line-height:30px
}
.bg .text-bg .content-center .pinlun .mingzi[data-v-6964f079]{font-size:16px;font-weight:bold
}
.bg .text-bg .content-center .pinlun .pshijian[data-v-6964f079]{font-size:14px;color:#a4a4a4;padding-top:5px
}
.bg .text-bg .content-center .pinlun .pcontent[data-v-6964f079]{font-size:16px;padding-top:10px;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .pinlun .pimg[data-v-6964f079]{max-width:50%;margin-top:20px
}
}
@media screen and (min-width: 960px) and (max-width: 1287px){
.bg[data-v-6964f079]{width:100%
}
.bg .logo-bg[data-v-6964f079]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-6964f079]{width:800px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-6964f079]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-6964f079]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-6964f079]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-6964f079]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-6964f079]{color:#ffffff;width:750px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-6964f079]{font-size:30px;margin:0
}
.bg .center-bg .mianshu .content[data-v-6964f079]{margin:10px 0 0 0;font-size:14px
}
.bg .center-bg .ready-code[data-v-6964f079]{width:750px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-6964f079]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-6964f079]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-6964f079]{width:100%
}
.bg .text-bg .content-center[data-v-6964f079]{width:800px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-6964f079]{margin:0;font-size:24px;text-align:center;padding-top:33px
}
.bg .text-bg .content-center .shijian[data-v-6964f079]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-6964f079]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-6964f079]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-6964f079]{max-width:50%
}
.bg .text-bg .content-center .pinlun[data-v-6964f079]{padding:10px 50px;line-height:30px
}
.bg .text-bg .content-center .pinlun .mingzi[data-v-6964f079]{font-size:16px;font-weight:bold
}
.bg .text-bg .content-center .pinlun .pshijian[data-v-6964f079]{font-size:14px;color:#a4a4a4;padding-top:5px
}
.bg .text-bg .content-center .pinlun .pcontent[data-v-6964f079]{font-size:16px;padding-top:10px;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .pinlun .pimg[data-v-6964f079]{max-width:50%;margin-top:20px
}
}
@media screen and (min-width: 769px) and (max-width: 959px){
.bg[data-v-6964f079]{width:100%
}
.bg .logo-bg[data-v-6964f079]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-6964f079]{width:600px;margin:0 auto;padding:20px 0px
}
.bg .logo-bg .logo-div img[data-v-6964f079]{width:51px;height:51px;min-width:51px;margin-right:10px;border-radius:10px
}
.bg .logo-bg .logo-div h2[data-v-6964f079]{font-size:20px;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-6964f079]{font-size:14px;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-6964f079]{width:100%;height:320px;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;opacity:0.9;background-size:cover
}
.bg .center-bg .mianshu[data-v-6964f079]{color:#ffffff;width:600px;margin:0 auto;padding-top:40px
}
.bg .center-bg .mianshu h2[data-v-6964f079]{font-size:26px;margin:0
}
.bg .center-bg .mianshu .content[data-v-6964f079]{margin:10px 0 0 0;font-size:14px
}
.bg .center-bg .ready-code[data-v-6964f079]{width:600px;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-6964f079]{width:123px;margin-right:80px;margin-top:33px
}
.bg .center-bg .ready-code .code-title[data-v-6964f079]{width:123px;padding-top:10px;color:#ffffff;text-align:center;font-size:16px
}
.bg .text-bg[data-v-6964f079]{width:100%
}
.bg .text-bg .content-center[data-v-6964f079]{width:600px;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-6964f079]{margin:0;font-size:24px;text-align:center;padding:33px 33px 0 33px
}
.bg .text-bg .content-center .shijian[data-v-6964f079]{margin:22px 0 0 0;font-size:14px;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-6964f079]{padding:0 50px;font-size:16px;line-height:30px
}
.bg .text-bg .content-center .content-div .wenzi[data-v-6964f079]{padding:20px 0;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-6964f079]{max-width:50%
}
.bg .text-bg .content-center .pinlun[data-v-6964f079]{padding:10px 50px;line-height:30px
}
.bg .text-bg .content-center .pinlun .mingzi[data-v-6964f079]{font-size:16px;font-weight:bold
}
.bg .text-bg .content-center .pinlun .pshijian[data-v-6964f079]{font-size:14px;color:#a4a4a4;padding-top:5px
}
.bg .text-bg .content-center .pinlun .pcontent[data-v-6964f079]{font-size:16px;padding-top:10px;letter-spacing:2px;text-align:justify
}
.bg .text-bg .content-center .pinlun .pimg[data-v-6964f079]{max-width:50%;margin-top:20px
}
}
@media screen and (max-width: 768px){
.bg[data-v-6964f079]{width:100%
}
.bg .logo-bg[data-v-6964f079]{width:100%;background:#ffffff
}
.bg .logo-bg .logo-div[data-v-6964f079]{width:6.9rem;margin:0 auto;padding:0.2rem 0rem
}
.bg .logo-bg .logo-div img[data-v-6964f079]{width:0.9rem;height:0.9rem;min-width:0.9rem;margin-right:0.1rem;border-radius:0.1rem
}
.bg .logo-bg .logo-div h2[data-v-6964f079]{font-size:0.36rem;margin:0;color:#000
}
.bg .logo-bg .logo-div .content-name[data-v-6964f079]{font-size:0.28rem;margin:0;color:#a4a4a4
}
.bg .center-bg[data-v-6964f079]{width:100%;height:3.8rem;background-image:url(../../static/img/bg1.b3a8e10.png);background-position:90% 40%;position:relative;background-size:cover;opacity:0.9
}
.bg .center-bg .mianshu[data-v-6964f079]{color:#ffffff;width:6.9rem;margin:0 auto;padding-top:0.3rem
}
.bg .center-bg .mianshu h2[data-v-6964f079]{font-size:0.36rem;margin:0
}
.bg .center-bg .mianshu .content[data-v-6964f079]{margin:0.1rem 0 0 0;font-size:0.26rem
}
.bg .center-bg .ready-code[data-v-6964f079]{width:6.9rem;margin:0 auto
}
.bg .center-bg .ready-code img[data-v-6964f079]{width:1.23rem;margin-right:0.5rem;margin-top:0.33rem
}
.bg .center-bg .ready-code .code-title[data-v-6964f079]{width:1.23rem;padding-top:0.1rem;color:#ffffff;text-align:center;font-size:0.2rem
}
.bg .text-bg[data-v-6964f079]{width:100%
}
.bg .text-bg .content-center[data-v-6964f079]{width:6.9rem;background-color:#ffffff;margin:0 auto
}
.bg .text-bg .content-center h2[data-v-6964f079]{margin:0;font-size:0.36rem;text-align:center;padding:0.2rem 0.2rem 0 0.2rem
}
.bg .text-bg .content-center .shijian[data-v-6964f079]{margin:0.2rem 0 0 0;font-size:0.3rem;color:#a4a4a4;text-align:center
}
.bg .text-bg .content-center .content-div[data-v-6964f079]{padding:0 0.3rem;font-size:0.3rem;line-height:0.5rem
}
.bg .text-bg .content-center .content-div .wenzi[data-v-6964f079]{padding:0.2rem 0;letter-spacing:0.03rem;text-align:justify
}
.bg .text-bg .content-center .content-div img[data-v-6964f079]{max-width:50%
}
.bg .text-bg .content-center .pinlun[data-v-6964f079]{padding:0.2rem 0.3rem;line-height:0.5rem
}
.bg .text-bg .content-center .pinlun .mingzi[data-v-6964f079]{font-size:0.36rem;font-weight:bold
}
.bg .text-bg .content-center .pinlun .pshijian[data-v-6964f079]{font-size:0.3rem;color:#a4a4a4;padding-top:0.1rem
}
.bg .text-bg .content-center .pinlun .pcontent[data-v-6964f079]{font-size:0.3rem;padding-top:0.1rem;letter-spacing:0.03rem;text-align:justify
}
.bg .text-bg .content-center .pinlun .pimg[data-v-6964f079]{max-width:50%;margin-top:0.2rem
}
}
